import { connect } from "react-redux";
import PrivacyPolicy from "./PrivacyPolicy";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
