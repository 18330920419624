import { connect } from "react-redux";
import ContactUs from "./ContactUs";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
