import { connect } from "react-redux";
import AboutUs from "./AboutUs";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
