//routes

export const HOME = "/home";
export const CONTACT = "/contact-us";
export const OUR_GEAR = "/our-gear";
export const ABOUT_US = "/about-us";
export const FAQS = "/faqs";
export const THANK_YOU = "/thank-you";
export const CART = "/cart";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS = "/terms";
export const CCPA_PAGE = "/do-not-sell";
export const EULA = "/eula";