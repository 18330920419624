import S from "./Header.module.scss";
import React, { useState, useEffect } from "react";
import WhiteLogo from 'src/images/102.png'
import GreenLogo from 'src/images/93.png'
import { NavLink, useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import ShoppingCartSvg from 'src/images/cart.svg'
import Popup from 'reactjs-popup';
import ShoppingCart from "../ShoppingCart";
import { useShoppingCart } from 'use-shopping-cart'
const HEADER_LINKS = [{
    title: 'Home',
    link: '/home',
    exact: true,
    isIcon: false
}, {
    title: 'About',
    link: '/about-us',
    isIcon: false
}, {
    title: 'Store',
    link: '/our-gear',
    isIcon: false
},
{
    title: 'FAQs',
    link: '/faqs',
    isIcon: false
}, {
    title: 'Contact',
    link: '/contact-us',
    isIcon: false
},
{
    title: 'Cart',
    link: '/cart',
    isIcon: true
}
]
const Header = ({ isLight }) => {
    const [toggle, setToggle] = useState(false)
    const location = useLocation();
    const { cartCount } = useShoppingCart()
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const handleOpen = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    const isSticky = (e) => {
        const header = document.getElementById('header');
        const scrollTop = window.scrollY;
        scrollTop >= 10 ? header.classList.add(isLight ? S.light_background : S.dark_background) : header.classList.remove(isLight ? S.light_background : S.dark_background);
    };

    return (<header id="header" className={`${S.header}`}>
        <div className="container">
            <nav className={`navbar navbar-expand-lg px-0`} style={{ paddingBottom: 30 }}>
                <a className={`${S.navbar_brand} ${S.main_logo}`} href="/">
                    <img
                        src={isLight ? GreenLogo : WhiteLogo}
                        style={{ maxWidth: 350 }}
                        alt="Postablur"
                    />
                    {/* <p style={{ color: isLight ? '#04bc2c' : 'white', position: 'absolute', bottom: -10 }}>(postablur)</p> */}
                </a>
                <button className={S.navbar_toggler} onClick={() => setToggle(!toggle)}>
                    <span className={S.navbar_toggler_icon}><i className="fa fa-align-justify" aria-hidden="true" style={{ color: isLight ? 'black' : 'white' }}></i></span>
                </button>

                <div className={`navbar-collapse justify-content-end ${S.main_menu}`} id={S.main_menu}>
                    <ul className="navbar-nav ml-auto">
                        {HEADER_LINKS.map((option, idx) => (<li className={`${isLight ? S.light_option : S.dark_option} ${location.pathname === option.link ? S.active : ''}`} key={idx}>
                            {option.isIcon ? <Popup
                                contentStyle={{ zIndex: 99999999, position: 'fixed' }}
                                trigger={
                                    <div className={S.cart}><img src={ShoppingCartSvg} style={{ marginTop: -5 }} />{cartCount > 0 ? <p className={S.badge}>{cartCount}</p> : null}</div>
                                }
                                open={isOpen}
                                onOpen={handleOpen}
                                position={['bottom', 'bottom left']}
                                closeOnDocumentClick>
                                <div className={S.cart_container}><ShoppingCart handleClose={handleClose} showCancel={true} /></div>
                            </Popup> : <NavLink
                                exact={option.exact}
                                to={option.link}
                                activeClassName={S.active}
                            >
                                {option.title}
                            </NavLink>}</li>))}
                    </ul>
                </div>

                {toggle && (
                    <div className={`navbar-collapse justify-content-end ${S.main_menu}`}>
                        <ul className="navbar-nav ml-auto">
                            {HEADER_LINKS.map((option, idx) => (<li key={idx} className={`${S.dark_option} ${location.pathname === option.link ? S.active : ''}`}>
                                {option.isIcon ?
                                    <NavLink
                                        exact={option.exact}
                                        to={option.link}
                                        activeClassName={S.active}
                                    >
                                        {option.title}
                                    </NavLink> : <NavLink
                                        exact={option.exact}
                                        to={option.link}
                                        activeClassName={S.active}
                                    >
                                        {option.title}
                                    </NavLink>}</li>))}
                        </ul>
                    </div>
                )}
                <div onClick={() => history.push('/cart')} className={S.cart_alternative}><img src={ShoppingCartSvg} style={{ marginTop: -5 }} />{cartCount > 0 ? <p className={S.badge_alternative}>{cartCount}</p> : null}</div>
            </nav>
        </div>
    </header>)
}

export default Header;