//libs
import React from "react";
import FooterApple from 'src/images/icons/appstore.svg'
import PostablurLogo from 'src/images/banner_logo.png'
import { useHistory } from "react-router-dom";
import { ABOUT_US, CCPA_PAGE, EULA, FAQS } from "src/shared/constants";
import { SocialMediaStrip } from "src/components";

//styles
import S from "./Footer.module.scss";

const Footer = ({ }) => {
  const history = useHistory();
  const goTo = (route) => {
    history.push(route)
  }
  return (
    <footer className={S.footer}>
      <div className={S.footer_bg} >
        <div className={S.left_footer}>
          <img
            className={S.footer_left_img}
            src={PostablurLogo}
            alt="Footer left" />
          <img
            className={S.footer_left_img_2}
            src={PostablurLogo}
            alt="Footer left" />
        </div>
        <div>
          <img
            className={S.footer_right_img}
            src={PostablurLogo}
            alt="Footer right" />
          <img
            className={S.footer_right_img_2}
            src={PostablurLogo}
            alt="Footer right" />
        </div>
      </div>
      <div className="container" style={{ borderWidth: 2 }}>
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className={`d-flex ${S.footer_top}`}>
              <p className="mx-3 mt-0 mb-0">Create a Post, Add a Goal<span className={S.footer_span}>(the blur)</span>, Share it Online, & Withdraw earnings daily.</p>
              <a href="https://apps.apple.com/us/app/postablur/id1501336763" target='_blank'>
                <img
                  className={S.footer_logo}
                  src={FooterApple}
                  alt="Appstore" />
              </a>
            </div>
          </div>
        </div>
        <div className={S.footer_width}>
          <div className="row">

            <div className="col-xs-6 col-sm-6 col-md offset-md-1">
              <div className={S.footer_links}>
                <h2>Explore</h2>
                <ul className={`list-unstyled`}>
                  <li className={S.footer_li}>How It Works</li>
                  <li><div onClick={() => goTo(ABOUT_US)}>About Us</div></li>
                  <li onClick={() => goTo(FAQS)}>FAQs</li>
                </ul>
              </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-md">
              <div className={S.footer_links}>
                <h2>Customer Support</h2>
                <ul className="list-unstyled">
                  <li><a href="mailto:info@postablur.com">info@postablur.com</a></li>
                  <li>O# 214-215-0102</li>
                  <li>M-F 8am-5pm</li>
                </ul>
              </div>

            </div>
            {/* <div className="col-xs-6 col-sm-6 col-md">
              <div className={S.footer_links}>
                <h2>Company Info</h2>
                <ul className="list-unstyled">
                  <li>Press Kit</li>
                  <li>Changelog</li>
                  <li>Investor Deck.pdf</li>
                </ul>
              </div>
            </div> */}
            <div className="col-xs-6 col-sm-6 col-md">
              <div className={S.footer_links}>
                <h2>Help</h2>
                <ul className="list-unstyled">
                  <li> <span><a href="/privacy-policy">Privacy Policy</a> </span>  &#38; <span><a href="/terms">Terms</a> </span> </li>
                  <li onClick={() => goTo(CCPA_PAGE)}>Don’t Sell My Info</li>
                  <li>App <a href="/eula">EULA</a></li>

                </ul>
              </div>

            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-md-0">
            <div className={S.footer_bottom}>
              <div className={S.footer_icons}>
                <SocialMediaStrip />
              </div>
              <span className={S.footer_bottom_heading}>Postablur, Inc. Registered Tradmark & Copyright © Postablur App 2020 - 2023</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
