//libs
import React from "react";
//styles
import S from "./home.module.scss";
import AppStore from 'src/images/app_store.svg'
import PostaBlur from 'src/images/postablur.svg'
import TopMobile from 'src/images/mobile_frame/top-mobile.png'
import MobOne from 'src/images/mobile_frame/top1.png'
import MobTwo from 'src/images/mobile_frame/top2.png'
import MobThree from 'src/images/mobile_frame/top3.png'
import MobFour from 'src/images/mobile_frame/top4.png'
import MobFive from 'src/images/mobile_frame/top5.svg'
import PostablurLogo from 'src/images/banner_logo.png'
import { HeaderFooterHoc } from "src/components";
import { SocialMediaStrip } from "src/components";

const Home = ({ }) => {
  return (
    <HeaderFooterHoc>
      <div className={S.container}>
        <main className={S.main}>

          {/* End Header */}
          <div className={S.banner_sec}>
            <div className='container'>
              <div className={S.banner_wrap}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className={S.banner_summary}>
                      <div className={S.animated_pics}>
                        <img
                          className={S.blur_logo_2}
                          src={PostablurLogo}
                          alt="Postablur"
                        />
                        <img
                          className={S.blur_logo}
                          src={PostablurLogo}
                          alt="Postablur"
                        />
                      </div>

                      <h2>A better way to create
                        and monetize every post
                        you share on social media.</h2>
                      <p>Upload or create content, set a Like or Credit goal,
                        select to share it to your other social networks.
                        <br />Then your fans come to your post and each Like or
                        Credit they give you, incrementally unblurs your post.</p>
                      <div className={S.btn_apps}>
                        <a href="https://apps.apple.com/us/app/postablur/id1501336763" target="_blank">
                          <img
                            src={AppStore}
                            alt="Postablur"
                          />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.postablur.android&hl=en_US&gl=US" target="_blank">
                          <img
                            src={PostaBlur}
                            alt="Postablur"
                          />
                        </a>
                      </div>
                    </div>

                  </div>

                  <div className='col-md-6 text-md-right'>
                    <div className={S.home_right}>
                      <div className={S.rht_animated_pics}>
                        <img
                          className={S.b_logo1}
                          src={PostablurLogo}
                          alt="Postablur"
                        />
                        <img
                          className={S.b_logo2}
                          src={PostablurLogo}
                          alt="Postablur"
                        />
                      </div>

                      <div className={S.mobileslid}>
                        <img src={TopMobile} alt="" />
                      </div>
                      <div className={S.profilemsg}>
                        <img src={MobTwo} alt="top2" className="img-fluid" />
                      </div>
                      <div className={S.awesome}>
                        <img src={MobThree} alt="top3" className="img-fluid" />
                      </div>
                      <div className={S.profileone}><img src={MobFour} alt="top4" className="img-fluid" /></div>
                      <div className={S.emoji}><img src={MobFive} alt="top5" className="img-fluid" /></div>
                      <div className={S.profiletwo}><img src={MobOne} alt="top1" className="img-fluid" /></div>
                    </div>
                  </div>
                </div>
                <div className={`d-flex flex-wrap ${S.ban_btm}`}>
                  <SocialMediaStrip />
                  <p>Registered © CIRCA 2022-25 Postablur, Inc. Postablur App. <span><a href="/terms">Terms & Conditions</a>, <a href="/privacy-policy">Privacy Policy</a>, and <a href="/eula">EULA</a></span></p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </HeaderFooterHoc>
  );
};

export default Home;
