//libs
import { connect } from "react-redux";

//component
import HeaderFooterHoc from "./HeaderFooterHoc";

const mapStateToProps = (state) => {
  return {
  };
};
export default connect(mapStateToProps)(HeaderFooterHoc);
